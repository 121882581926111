<template>
  <div ref="svg">

</div>
</template>
<script>
 export default {
   props: [
       'url',
       'level'
   ],

   data () {
     return {
       routes:'',
     }
   },

   computed : {

   },

   mounted() {

     fetch(this.url)
         .then(r => r.text())
         .then(svgText => {


           this.$refs.svg.innerHTML  = svgText
         })
   }
 }
</script>