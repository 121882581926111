<template>
  <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="15"
      :loading="loading"
      :search="$statfilter.text"
      v-model:sort-by="$statfilter.sortBy"
  >
    <template v-slot:top>
      <list-filter></list-filter>
    </template>
  </v-data-table>
</template>
<script>


import ListFilter from "@/components/Stats/ListFilter.vue";

export default {
  components : {
    ListFilter
  },
  data() {
    return {
      headers: [
        {text: 'Gomb', value: 'name'},
        {text: 'Kattintások száma', value: 'count'},
      ],
      items: [],
      loading:false,
    }
  },

  methods : {
    changedDate () {
      this.loadItems()
    },
    loadItems () {
      this.loading = true;
      this.$api.get(`/stats/button?${this.$statfilter.getQuery()}`).then((resp)=>{
        this.items = resp.data
        this.loading = false;
      })
    }
  },

  computed : {
  },

  mounted () {
    this.loadItems()
  },

  watch : {
    '$statfilter.dateType' () {
      this.loadItems();
    },
    '$statfilter.date' () {
      this.loadItems();
    },
    '$statfilter.month' () {
      this.loadItems();
    }
  }
}
</script>