<template>
  <v-card>
    <v-card-title>
      Kategóriák
      <v-spacer></v-spacer>
      <v-btn @click="$map.editedCategory={}" small color="primary">Hozzáadás</v-btn>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="$map.categories"
        :items-per-page="15"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="editCategory(item)" icon class="mr-2">
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn icon @click="$map.deleteCategory=item">
          <v-icon
              small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>


    <v-dialog v-if="$map.editedCategory" v-model="$map.editedCategory" max-width="400" persistent>
      <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title v-if="$map.editedCategory.id">Kategória szerkesztése</v-card-title>
        <v-card-title v-else>Új kategória</v-card-title>
        <v-card-text>



          <v-tabs v-model="tab">
            <v-tab>
              HU
            </v-tab>
            <v-tab>
              EN
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item class="pt-4">
              <v-text-field :rules="[rules.required]" outlined label="megnevezes - HU" v-model="$map.editedCategory.name_hu"></v-text-field>
            </v-tab-item>
            <v-tab-item class="pt-4">
              <v-text-field outlined label="megnevezes - EN" v-model="$map.editedCategory.name_en"></v-text-field>
            </v-tab-item>
          </v-tabs-items>




          <v-row >
            <v-col>
              <p>Szín</p>
              <v-btn-toggle  v-model="$map.editedCategory.color" >
                <v-btn small v-for="color in colors" :key="color" :value="color" :color="color"></v-btn>
              </v-btn-toggle>


            </v-col>
          </v-row>


          <v-row>
            <v-col>
              <p>Tartalom típusa</p>
              <v-btn-toggle  v-model="$map.editedCategory.content_type">
                <v-btn :value="1">Kép</v-btn>
                <v-btn :value="2">Szám</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row v-if="$map.editedCategory.content_type == 1">
            <v-col>
              <p>Tartalom típusa</p>
              <single-image-uploader v-model="$map.editedCategory.image_id"></single-image-uploader>
            </v-col>
          </v-row>


          <v-row>
            <v-col>
              <v-checkbox label="Mindig csak a legközelebbit mutassa" v-model="$map.editedCategory.nearest"></v-checkbox>
            </v-col>
          </v-row>








        </v-card-text>
        <v-card-actions>
          <v-btn @click="resetCategory">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveCategory">Mentés</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="$map.deleteCategory" :value="true" width="400">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{$map.deleteCategory.name_hu }}</i> kategóriát?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$map.deleteCategory=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$map.doDeleteCategory()">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>

</template>

<script>

import shape1 from '../assets/map/shape1.svg'
import shape2 from '../assets/map/shape2.svg'
import shape3 from '../assets/map/shape3.svg'
import shape4 from '../assets/map/shape4.svg'
import SingleImageUploader from "@/components/SingleImageUploader";


export default {
  components: {
    SingleImageUploader
    
  },
  data() {
    return {
      shape1,
      shape2,
      shape3,
      shape4,

      tab:null,
      lastState:null,
      editedItem: null,
      items: [],
      valid:false,
      rules: {
        required: value => !!value || 'Kötelező kitölteni.',
      },
      headers: [
        {
          text: 'Megnevezés',
          value: 'name_hu',
        },
        {text: 'Műveletek', value: 'actions', sortable: false, width: 120},
      ],

      colors: [
        "#00A4E4",
        "#F7941D",
        "#FFF",
      ]
    }
  },

  methods : {
    editCategory (item) {
      this.lastState = JSON.parse(JSON.stringify(item));
      this.$map.editedCategory = item;
    },
    resetCategory () {
      for (var i in this.lastState) {
        this.$map.editedCategory[i] = this.lastState[i]
      }
      this.$map.editedCategory = null;
    },
    saveCategory() {
      this.$refs.form.validate();
      if (!this.valid) {
        return true;
      }
      this.$map.saveCategory()
    }
  },
  mounted() {
  }
}

</script>