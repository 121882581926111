<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Szövegek</div>
        <v-spacer></v-spacer>
        <v-btn @click="newText" color="primary">Hozzáadás</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="editItem=item" icon class="mr-2">
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>

          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="deleteItem" v-model="deleteItem" width="500">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{deleteItem.name}}</i> adatlapját?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteItem=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="editItem" v-model="editItem" width="500">
      <v-card>
        <v-card-title>Szerkesztés</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field outlined label="Azonosító" v-model="editItem.key"></v-text-field>

              <v-text-field v-for="lang in langs" outlined :label="lang" v-model="editItem[lang]"></v-text-field>


            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="editItem=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="doSave" :loading="progress">Mentés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>
<script>

  export default {
    data() {
      return {
        items: [],
        deleteItem: null,
        editItem: null,
        progress: false,
        langs: []
      }
    },

    computed : {
      headers () {
        const list = [
          {text: 'Azonosító', value: 'key'},
        ];

        this.langs.forEach(code => {
          list.push({
            text: code,
            value: code,
          })
        })

        list.push({text: 'Műveletek', value: 'actions', sortable:false, width: 40})

        return list;
      },
    },

    methods : {
      newText () {
        this.editItem = {
          key:'',
          hu:'',
          en:'',
          de:'',
        }
      },
      confirmDelete() {
        this.progress = true;

        this.$api.get(`/text/delete/${this.deleteItem.id}`).then(()=>{
          this.loadItems();
          this.deleteItem = null;
          this.loadItems();
          this.progress = false;

        })
      },
      loadItems() {
        this.$api.get(`/text/list`).then((resp)=>{
          this.items = resp.data
        })
      },
      loadLangs() {
        this.$api.get(`/language`).then((resp)=>{
          this.langs = resp.data.map(x => x.code)
        })
      },
      doSave () {
        this.progress = true;
        this.$api.post(`/text/save`, this.editItem).then((resp)=>{
          this.$snackbar.show('Módosítások elmentve');
          this.progress = false;
          this.editItem = null;
          this.loadItems()
        })
      }
    },

    mounted () {
      this.loadItems()
      this.loadLangs()
    }
  }
</script>