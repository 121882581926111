<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Térkép szerkesztés</div>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>

    <v-row>

      <v-col cols="8">
        <v-card class="mb-4">
          <v-toolbar  dense elevation="0" :color="startAdding ? `light-green` : ``">
            <div v-if="startAdding" style="color:white">
              Kattints a térképre a hely megjelöléséhez
            </div>
            <v-spacer></v-spacer>

            <v-btn icon class="mr-2"  @click="zoomIn"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
            <v-btn icon class="mr-6"  @click="zoomOut"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
            <v-btn color="primary" small v-if="!startAdding" @click="startAdding =true">Új poi hozzáadása</v-btn>
            <v-btn small v-else @click="startAdding =false">Mégse</v-btn>
          </v-toolbar>

          <v-card-text v-show="$map.routeParsed">

            <v-tabs v-model="tab" @change="tabChanged">
              <v-tab>Foldszint</v-tab>
              <v-tab>1. emelet</v-tab>
              <v-tab>2. emelet</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item eager>

                <div class="zoom-container" :class="zoomClass">
                  <div class="map-container" :class="{ adding : startAdding}" @click="add" @mousemove="move" @mouseup="mouseUp">

                    <svg-map id="svg-map" ref="svg0" :url="map0" level="0">
                      <template v-slot:routes>


                        <line v-for="poi in $map.pois.filter(x=> x.level == 0)"
                              stroke-linejoin="round"
                              stroke-linecap="round"
                              stroke="#F0F" stroke-width="2"
                              :key="poi.id"
                              :id="`p-${poi.id}`"
                              :label="poi.nameHu"
                              :x1="poi.x1"
                              :x2="poi.x2"
                              :y1="poi.y1"
                              :y2="poi.y2"
                        ></line>

                        <circle/>


                        <line v-if="$map.pathPlaceholder" stroke="#F0F" stroke-width="4"
                              :x1="$map.pathPlaceholder.x1"
                              :x2="$map.pathPlaceholder.x2"
                              :y1="$map.pathPlaceholder.y1"
                              :y2="$map.pathPlaceholder.y2"></line>

                      </template>
                      <template v-slot:pois>

                        <poi-poi v-for="poi in $map.pois.filter(x=> x.level == 0)" :key="poi.id" :poi="poi"></poi-poi>

                      </template>
                    </svg-map>

                  </div>

                </div>


              </v-tab-item>

              <v-tab-item eager>

                <div class="zoom-container" :class="zoomClass">
                  <div class="map-container" :class="{ adding : startAdding}" @click="add" @mousemove="move" @mouseup="mouseUp">

                    <svg-map id="svg-map1" ref="svg1" :url="map1" level="1">
                      <template v-slot:routes>


                        <line v-for="poi in $map.pois.filter(x=> x.level == 1)"
                              stroke-linejoin="round"
                              stroke-linecap="round"
                              stroke="#F0F" stroke-width="2"
                              :key="poi.id"
                              :id="`p-${poi.id}`"
                              :label="poi.nameHu"
                              :x1="poi.x1"
                              :x2="poi.x2"
                              :y1="poi.y1"
                              :y2="poi.y2"
                        ></line>

                        <circle/>

                        <line v-if="$map.pathPlaceholder" stroke="#F0F" stroke-width="4"
                              :x1="$map.pathPlaceholder.x1"
                              :x2="$map.pathPlaceholder.x2"
                              :y1="$map.pathPlaceholder.y1"
                              :y2="$map.pathPlaceholder.y2"></line>

                      </template>
                      <template v-slot:pois>

                        <poi-poi v-for="poi in $map.pois.filter(x=> x.level == 1)" :key="poi.id" :poi="poi"></poi-poi>

                      </template>
                    </svg-map>

                  </div>

                </div>


              </v-tab-item>

              <v-tab-item eager>

                <div class="zoom-container" :class="zoomClass">
                  <div class="map-container" :class="{ adding : startAdding}" @click="add" @mousemove="move" @mouseup="mouseUp">

                    <svg-map id="svg-map2" ref="svg2" :url="map2" level="2">
                      <template v-slot:routes>


                        <line v-for="poi in $map.pois.filter(x=> x.level == 2)"
                              stroke-linejoin="round"
                              stroke-linecap="round"
                              stroke="#F0F" stroke-width="2"
                              :key="poi.id"
                              :id="`p-${poi.id}`"
                              :label="poi.nameHu"
                              :x1="poi.x1"
                              :x2="poi.x2"
                              :y1="poi.y1"
                              :y2="poi.y2"
                        ></line>

                        <circle/>

                        <line v-if="$map.pathPlaceholder" stroke="#F0F" stroke-width="4"
                              :x1="$map.pathPlaceholder.x1"
                              :x2="$map.pathPlaceholder.x2"
                              :y1="$map.pathPlaceholder.y1"
                              :y2="$map.pathPlaceholder.y2"></line>

                      </template>
                      <template v-slot:pois>

                        <poi-poi v-for="poi in $map.pois.filter(x=> x.level == 2)" :key="poi.id" :poi="poi"></poi-poi>

                      </template>
                    </svg-map>

                  </div>

                </div>


              </v-tab-item>
            </v-tabs-items>

          </v-card-text>

          <v-card-text v-if="!$map.routeParsed" class="pa-8 d-flex justify-center">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>

          </v-card-text>
        </v-card>

        <v-card class="path-map">
          <v-card-title>Utvonal verziok</v-card-title>
          <v-card-text>
            <v-select @change="loadPath" :loading="!mapPaths.length" dense hide-details label="Útvonal verziók" :items="mapPaths" item-text="name" item-value="id"  outlined v-model="selectedMapPath" clearable></v-select>
          </v-card-text>
          <v-card-text>

            <v-tabs v-model="pathTab">
              <v-tab>Foldszint</v-tab>
              <v-tab>1. emelet</v-tab>
              <v-tab>2. emelet</v-tab>
            </v-tabs>

            <v-tabs-items v-model="pathTab">
              <v-tab-item eager>
                <svg-mapp ref="path-map-0" :url="map0" level="1"></svg-mapp>
              </v-tab-item>
              <v-tab-item eager>
                <svg-mapp ref="path-map-1" :url="map1" level="2"></svg-mapp>
              </v-tab-item>
              <v-tab-item eager>
                <svg-mapp ref="path-map-2" :url="map2" level="3"></svg-mapp>
              </v-tab-item>
            </v-tabs-items>




          </v-card-text>
        </v-card>

      </v-col>

      <v-col cols="4">
        <poi-category class="mb-4"></poi-category>

        <poi-list class="mb-4"></poi-list>
      </v-col>
    </v-row>



  </v-container>
</template>

<script>

import SvgMap from '../components/SvgMap.vue';
import SvgMapp from '../components/SvgMapp.vue';


import PoiCategory from "@/components/PoiCategory";
import PoiList from "@/components/PoiList";
import PoiPoi from "@/components/PoiPoi";
import map0 from '../assets/map-level-0.svg'
import map1 from '../assets/map-level-1.svg'
import map2 from '../assets/map-level-2.svg'

export default {
  components : {
    PoiPoi,
    PoiList,
    PoiCategory,
    SvgMap,
    SvgMapp
  },
  props: [
    'poi'
  ],
  data() {
    return {
      map0,
      map1,
      map2,
      startAdding:false,
      zoom:0,
      tab:0,
      pathTab:0,
      dragPoi:null,
      mapPaths:[],
      selectedMapPath:null,

    }
  },

  computed : {
    zoomClass () {
      return `zoomed-${this.zoom}`
    }
  },

  methods : {
    setDrag (poi) {
      console.log('setDrag', poi)

      this.dragPoi = poi;
    },
    stopDrag () {
      console.log('stopDrag', this.dragPoi)
    },

    tabChanged () {
      this.$map.currentLevel = this.tab;
    },

    zoomIn () {
      this.zoom++;

      if (this.zoom > 2) {
        this.zoom = 2;
      }
    },
    zoomOut () {
      this.zoom--;

      if (this.zoom < 0) {
        this.zoom = 0;
      }
    },

    addNew (x1,y1,x2,y2) {
      this.$map.addPoi({
        x1: x1,
        y1: y1,
        x2: closest.x.toFixed(1),
        y2: closest.y.toFixed(1)
      })
    },
    mouseUp (e) {
      this.$map.dragPoi.stopDrag();
    },
    move (e) {


      if (this.$map.dragPoi) {
        const xratio = this.$map.currentSvg.clientWidth / this.$map.currentSvg.viewBox.baseVal.width;
        const yratio = this.$map.currentSvg.clientHeight / this.$map.currentSvg.viewBox.baseVal.height;

        this.$map.dragPoi.poi.x1 = e.layerX / xratio;
        this.$map.dragPoi.poi.y1 = e.layerY / yratio;

        const nearest = this.$map.findNearest(this.$map.dragPoi.poi.x1, this.$map.dragPoi.poi.y1)

        this.$map.dragPoi.poi.x2 = nearest.x
        this.$map.dragPoi.poi.y2 = nearest.y;

        return;
      }

      if (!this.startAdding) {
        return
      }
      const xratio = this.$map.currentSvg.clientWidth / this.$map.currentSvg.viewBox.baseVal.width;
      const yratio = this.$map.currentSvg.clientHeight / this.$map.currentSvg.viewBox.baseVal.height;

      const x = e.layerX / xratio;
      const y = e.layerY / yratio;

      console.log('move', this.$map.currentLevel, this.$map.currentSvg.clientWidth, this.$map.currentSvg.viewBox.baseVal.width, this.$map.currentSvg)

      if (!isFinite(x) || !isFinite(y)) {
        return;
      }

      const nearest = this.$map.findNearest(x,y)

      this.$map.pathPlaceholder = {
        x1 : x,
        y1 : y,
        x2 : nearest.x,
        y2 : nearest.y,
      }
    },
    add (e) {
      if (!this.startAdding) {
        return
      }
      const xratio = this.$map.currentSvg.clientWidth / this.$map.currentSvg.viewBox.baseVal.width;
      const yratio = this.$map.currentSvg.clientHeight / this.$map.currentSvg.viewBox.baseVal.height;

      const x = e.layerX / xratio;
      const y = e.layerY / yratio;

      this.$map.addPoi(x, y);

      this.$map.pathPlaceholder = false;
      this.startAdding = false;
    },

    loadPath () {
      const map = this.mapPaths.find(x => x.id === this.selectedMapPath);

      this.$refs['path-map-0'].$el.querySelector('#routes').innerHTML = map.path_level_1;
      this.$refs['path-map-1'].$el.querySelector('#routes').innerHTML = map.path_level_2;
      this.$refs['path-map-2'].$el.querySelector('#routes').innerHTML = map.path_level_3;
    }
  },

  mounted () {

    this.$api.get('/map-path/list')
        .then((resp) => {
          this.mapPaths =  resp.data;
          this.selectedMapPath = 1;

          this.loadPath();

        })


  }
}
</script>