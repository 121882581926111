<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Vészhelyzeti üzenetek</div>
        <v-spacer></v-spacer>
        <v-btn to="/veszhelyzet/uj" color="primary">Hozzáadás</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
        >

          <template v-slot:[`item.is_active`]="{ item }">
            <template v-if="item.is_active">
              <v-icon color="success">mdi-check-circle</v-icon> Aktív
            </template>
            <template v-else>
              <v-icon>mdi-stop-circle-outline</v-icon> Inaktív
            </template>
          </template>



          <template v-slot:[`item.text`]="{ item }">
            {{ plaintext(item.text) }}
          </template>
          <template v-slot:[`item.color`]="{ item }">
            <div :style="`width:26px;text-align:center;background: ${item.bg_color};color:${item.font_color}`">A</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :to="`/veszhelyzet/${item.id}`" icon class="mr-2">
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn  @click="deleteItem=item" icon>
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="deleteItem" v-model="deleteItem" width="500">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{deleteItem.name}}</i> adatlapját?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteItem=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

  export default {
    data() {
      return {
        headers: [
          {text: 'Státusz', value: 'is_active', sortable:false, width: 120},
          {text: 'Név', value: 'name'},
          {text: 'Szöveg', value: 'text', sortable:false},
          {text: 'Szín', value: 'color', sortable:false},
          {text: 'Műveletek', value: 'actions', sortable:false, width: 120},
        ],
        items: [],
        deleteItem: null,
      }
    },

    methods : {
      plaintext (html) {
        var dom = new DOMParser().parseFromString(html, 'text/html');
        return dom.body.textContent;
      },
      confirmDelete() {
        this.$api.get(`/alert/delete/${this.deleteItem.id}`).then(()=>{
          this.loadItems();
          this.deleteItem = null;
          this.loadItems();
        })
      },
      loadItems() {
        this.$api.get(`/alert/list`).then((resp)=>{
          this.items = resp.data
        })
      }
    },

    mounted () {
      this.loadItems()
    }
  }
</script>