<template>
	<v-navigation-drawer v-bind="menuOptions" v-resize="$menu.onResize" :value="$menu.isOpen">
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="title d-flex align-center justify-center">
          <v-img
              max-width="160"
              contain
              :aspect-ratio="4/3" src="../assets/logo.svg"></v-img>
				</v-list-item-title>
				<v-list-item-subtitle>
					{{ userName }}
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>

		<v-divider></v-divider>


		<v-list dense nav>
			<v-list-item link v-for="item, i in menu" :to="item.link" :key="i" v-show="$auth.can(item.resource, 'manage')" >
				<v-list-item-icon>
					<v-icon>{{item.icon}}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{item.text}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>


    <template v-slot:append>
      <div class="pa-8" v-if="$menu.isOpen">
        <v-btn :href="`/download?t=${(new Date()).getTime()}`" target="_blank" style="font-size: 12px; text-transform: none;text-align: left">
          <v-icon small class="mr-2">mdi-download</v-icon>
          Kiosk kliens letöltése
        </v-btn>
      </div>
    </template>



	</v-navigation-drawer>
</template>

<script>

  import {mdiAccount} from '@mdi/js';
  import { mdiStore } from '@mdi/js';
  import { mdiElephant } from '@mdi/js';
  import { mdiAlertOctagon } from '@mdi/js';
  import { mdiGoogleTranslate } from '@mdi/js';
  import { mdiMap } from '@mdi/js';
  import { mdiYoutube } from '@mdi/js';
  import { mdiDesktopClassic } from '@mdi/js';
  import { mdiFormatListCheckbox } from '@mdi/js';
  import { mdiChartBar } from '@mdi/js';

  export default {
    components: {},

    computed: {
      userName() {
        return window.user ? window.user.name : '';
      },
      menuOptions() {
        return {
          app: true,
          stateless: true,
          permanent: true,
          'mini-variant': !this.$menu.isOpen,
        }
      },
      menu() {
        return [
          {icon: mdiStore, text: 'Üzletek', link: '/uzletek', resource: '/store'},
        //  {icon: mdiAlarm, text: 'Kuponok', link: '/napi-programok'},
          {icon: mdiAlertOctagon, text: 'Vészhelyzet üzemmód', link: '/veszhelyzet',  resource: '/alert'},
          {icon: mdiMap, text: 'Térkép', link: '/terkep', resource: '/map'},
          {icon: mdiYoutube, text: 'Videók', link: '/videok', resource: '/video'},
          {icon: mdiFormatListCheckbox, text: 'Lejátszási listák', link: '/listak', resource: '/playlist'},
          {icon: mdiDesktopClassic, text: 'Eszközök', link: '/eszkozok', resource: '/device'},
          {icon: mdiAccount, text: 'Felhasználók', link: '/felhasznalok', resource: '/user'},
          {icon: mdiGoogleTranslate, text: 'Fordítások', link: '/forditasok', resource: '/text'},
          {icon: mdiChartBar, text: 'Statisztikák', link: '/stats', resource: '/stats'},
		];
      }
    },

    data() {
      return {
        advertCount: 0,
        favouriteCount: 0,
        savedSearchCount: 0,
      }
    },

    methods: {
    },

    mounted() {
    }
  };
</script>