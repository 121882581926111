<template>
	<v-container fluid>
		<v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Statisztikák</div>
        <v-spacer></v-spacer>
      </v-col>
		</v-row>
		<v-row>

			<v-col>

        <v-tabs
            v-model="tab"
            bg-color="primary"
        >
          <v-tab>Főoldali gombok</v-tab>
          <v-tab>Oldal megtekintések</v-tab>
          <v-tab>Üzlet megtekintések</v-tab>
        </v-tabs>

			</v-col>
		</v-row>

    <v-row>
      <v-col>
        <div v-if="tab===0">
          <homebuttons></homebuttons>
        </div>
        <div v-if="tab===1">
          <pages></pages>
        </div>
        <div v-if="tab===2">
          <stores></stores>
        </div>
        <div>
          <v-btn small @click="handleExportButtonClick">exportálás</v-btn>
        </div>
      </v-col>
    </v-row>


	</v-container>
</template>
<script>

  import Pages from "@/components/Stats/Pages.vue";
  import Homebuttons from "@/components/Stats/Homebuttons.vue";
  import Stores from "@/components/Stats/Stores.vue";

  export default {
		components : {
      Stores,
      Homebuttons,
      Pages
    },
		data() {
	    return {
        tab: 1
      }
		},
    methods:{
      handleExportButtonClick() {
        this.$api.get(`/stats/export?${this.$statfilter.getQuery()}`, {responseType: 'blob'}).then(({data, headers}) => {
          let filename = 'kiosk-stats.xlsx';
          if(headers['content-disposition']) {
            const [, serverFilename] = headers['content-disposition'].match(/filename="(.*)"/) ?? [];
            if(serverFilename) {
              filename = serverFilename;
            }
          }
          this.$useDownloadBlob(data, filename);
        });
      }
    }
	}
</script>