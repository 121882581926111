<template>
  <v-card>
    <v-card-title>
      Poik
      <v-row dense class="pt-4">
        <v-col cols="12">
<v-select
          v-model="searchCategory"
          label="Kategória"
          clearable
          outlined
          dense
          single-line
          hide-details
          :items="$map.categories" item-text="name_hu" item-value="id"
      ></v-select>
      </v-col>

      <v-col cols="12">
<v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          label="Keresés"
          outlined
          dense
          single-line
          hide-details
      ></v-text-field>
      </v-col>
      </v-row>
      

      
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="pois"
        :search="search"
        :items-per-page="15"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="editPoi(item)" icon class="mr-2">
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn icon @click="$map.deletePoi=item">
          <v-icon
              small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>



    <v-dialog v-if="$map.editedPoi" v-model="$map.editedPoi" max-width="600" persistent>
      <v-form v-model="valid" ref="form">
        <v-card>
        <v-card-title v-if="$map.editedPoi.id">Poi szerkesztése</v-card-title>
        <v-card-title v-else>Új poi adatai</v-card-title>
        <v-card-text>


              <v-tabs  v-model="langTab">
                <v-tab>
                  HU
                </v-tab>
                <v-tab>
                  EN
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="langTab">
                <v-tab-item class="pt-4">
                  <v-text-field outlined label="Megnevezés - HU" v-model="$map.editedPoi.name_hu"></v-text-field>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-text-field outlined label="Megnevezés - EN" v-model="$map.editedPoi.name_en"></v-text-field>
                </v-tab-item>
              </v-tabs-items>

         <v-row>
           <v-col>
             <v-select label="Típus" outlined v-model="$map.editedPoi.category_id" :items="$map.categories" item-text="name_hu" item-value="id"></v-select>
           </v-col>
           <v-col>
             <v-select label="Zóna" outlined v-model="$map.editedPoi.zone" :items="$map.zones" item-text="text" item-value="id"></v-select>
           </v-col>
         </v-row>

          <v-row v-if="$map.getEditedPoiCategory().content_type == 2">
            <v-col>
              <v-text-field v-if="$map.getEditedPoiCategory().content_type == 2" v-model="$map.editedPoi.text" label="Sorszám" outlined></v-text-field>

            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select label="Készülékek" outlined v-model="$map.editedPoi.device_ids" multiple :items="devices" item-text="name" item-value="id"></v-select>
            </v-col>
            <v-col cols="6">
              <single-image-uploader v-if="$map.getEditedPoiCategory().content_type == 1" v-model="$map.editedPoi.image_id"></single-image-uploader>
            </v-col>
          </v-row>




        </v-card-text>
        <v-card-actions>
          <v-btn @click="resetPoi">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="savePoi">Mentés</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="$map.deletePoi" :value="true" max-width="400">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{$map.deletePoi.name_hu }}</i> helyet?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$map.deletePoi=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$map.doDeletePoi()">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-card>

</template>

<script>

import SingleImageUploader from "@/components/SingleImageUploader";


export default {
  components: {
    SingleImageUploader
  },
  props : [
      'categories'
  ],
  data() {
    return {
      langTab: null,
      search: '',
      searchCategory: '',
      items: [],
      currentLocation: null,
      valid:false,
      lastState:null,
      devices:[],
      rules: {
        required: value => !!value || 'Kötelező kitölteni.',
      },
      headers: [
        {
          text: 'Megnevezés',
          value: 'name_hu',
        },
        {text: 'Műveletek', value: 'actions', sortable:false, width: 120},

      ],
      groups: [
        {
          id: 1,
          text: 'WC',
        },
        {
          id: 2,
          text: 'Dohányzóhely',
        },
        {
          id: 3,
          text: 'Pénztár',
        },
      ]
    }
  },

  computed : {
    pois () {
      if (!this.searchCategory) {
        return this.$map.pois;
      }

      return this.$map.pois.filter(x => x.category_id == this.searchCategory);
    }
  },

  methods : {
    resetPoi () {
      this.$map.editedPoi = null;
    },
    editPoi (item) {
      this.$map.editPoi(item)
    },
    savePoi () {
      this.$refs.form.validate();
      if (!this.valid) {
        return true;
      }
      this.$map.savePoi()
    }
  },
  mounted() {

    this.$api.get('/device/list')
        .then((resp) => {
          this.devices =  resp.data;
        })
  },
  watch : {

  }
}

</script>