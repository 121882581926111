import Vue from 'vue'
import api from '../plugins/api.js'

var app = new Vue({

  data: () => ({
    categories:[],
    zones:[
      {id: 1, text: 'check-in'},
      {id: 2, text: 'indulás'},
      {id: 3, text: 'poggyász'},
      {id: 4, text: 'érkezés'},
    ],
    pois:[],
    routePoints:[],
    devices:[],
    editedPoi:null,
    editedCategory:null,
    deletePoi:null,
    deleteCategory:null,
    pathPlaceholder:null,
    svgs: [],
    sizes : [],
    currentLevel:0,
    alreadySavedMap: false,
    loadedSvgCount:0,
    routeParsed: false,
    dragPoi:null,
  }),

  computed : {
    currentSvg () {
      return this.svgs[this.currentLevel]
    }
  },

  methods: {
    addSvg (level, svg) {
      this.svgs[level] = svg;
      this.loadedSvgCount++;
      console.log('this.loadedSvgCount', this.loadedSvgCount)
      if (this.loadedSvgCount === 3) {
        this.$map.parseRoutes()
      }
    },
    editCategory(){
      this.editedCategory = {
        nameHu:null,
        nameEn:null,
        shape:null,
        contentType:null,
        color:null,
        imageId:null,
      }
    },

    reloadPois () {
      return new Promise(resolve => {
        api.$api.get('/poi').then(resp => {
          this.pois = resp.data;
          resolve();
        })
      })
    },

    reloadCategories () {
      api.$api.get('/poi-category').then(resp => {
        this.categories = resp.data;
      })
    },

    savePoi(){
      if (this.editedPoi._vm) {
        this.editedPoi._vm = null
      }
      api.$api.post('/poi/save', this.editedPoi)
        .then(() => {
          this.editedPoi = null;
          this.reloadPois().then(this.saveMap)
        })
    },

    saveMap(){

      console.log('saveMap')

      const files = [];

      this.svgs.forEach(svg => {

        const newSvg = document.createElement('div');
        newSvg.innerHTML = svg.outerHTML;

        newSvg.querySelectorAll('.poi').forEach(x => {

          console.log('poi',x)

          var image = x.querySelector('image');

          if (image) {
            const poi = this.pois.find(b => {
              return b.id == x.id.replace('poi-', '')
            });

            if (poi.image_id) {
              image.setAttribute('href', `[${poi.image_id}]`);
              return
            }
            else {
              const category = this.categories.find(c => c.id == poi.category_id);
              if (category.image_id) {
                image.setAttribute('href', `[${category.image_id}]`);
              }
            }
          }
        })


        console.log('newSvg.innerHTML', newSvg.innerHTML.substr(newSvg.innerHTML.indexOf('pois')))

        files.push(new File([newSvg.innerHTML], 'map.svg'));
      })


      api.$api.post('/map/save', {
        'level0': files[0],
        'level1': files[1],
        'level2': files[2]
      }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          this.alreadySavedMap = true;
        })
    },

    saveCategory(){
      api.$api.post('/poi-category/save', this.editedCategory)
        .then(() => {
          this.editedCategory = null;
          this.reloadCategories()
        })
    },

    getEditedPoiCategory () {
      const category = this.categories.filter(x => x.id == this.editedPoi.category_id);
      return category.length ? category[0] : {}
    },

    addPoi (x, y) {
      const nearest = this.findNearest(x ,y)
      this.editedPoi = {
        x1: x,
        y1: y,
        x2: nearest.x,
        y2: nearest.y,
        level: this.currentLevel
      }
    },

    editPoi (item) {
      const jsonitem = {
        category_id: item.category_id,
        group: item.group,
        id: item.id,
        image_id: item.image_id,
        level: item.level,
        name_en: item.name_en,
        name_hu: item.name_hu,
        text: item.text,
        x1: item.x1,
        x2: item.x2,
        y1: item.y1,
        y2: item.y2,
        zone: item.zone,
        device_ids: item.device_ids,
      };
      this.$map.editedPoi = JSON.parse(JSON.stringify(jsonitem));
    },

    doDeletePoi () {
      api.$api.get(`/poi/delete/${this.deletePoi.id}`).then(() => {
        this.deletePoi = null;
        this.reloadPois()
      })
    },

    doDeleteCategory () {
      api.$api.get(`/poi-category/delete/${this.deleteCategory.id}`).then(() => {
        this.deleteCategory = null;
        this.reloadCategories()
      })
    },

    findNearest (x, y) {
      function distance(p) {
        return Math.sqrt(Math.pow(x - p.x, 2) + Math.pow(y - p.y, 2))
      }

      const closest = this.routePoints[this.currentLevel].reduce((a, b) => distance(a) < distance(b) ? a : b);

      return {
        x: closest.x.toFixed(1),
        y: closest.y.toFixed(1),
      }
    },

    parseRoutes () {
      this.svgs.forEach((svg, index) => {
        this.sizes.push({
          width: svg.viewBox.baseVal.width,
          height: svg.viewBox.baseVal.height,
        })
        const points = []
        svg.getAttribute('viewBox').split(' ')[2];
        svg.querySelectorAll('#routes polyline, #routes polygon ').forEach((elem) => {
          for (var i=0; i< elem.points.length; i++) {
            points.push(elem.points[i])
          }
        })
        this.routePoints[index] = points
      })

      console.log('routeParsed')
      this.routeParsed = true;
    }
  },

  created() {
    this.reloadPois();
    this.reloadCategories();

    window.$map = this
  },
})

export default {
  install (Vue) {
    Vue.prototype.$map = app
  }
}