<template>
  <v-container fluid>

    <v-row class="pa-4">
      <v-btn @click="save" :disabled="saveProgress" color="primary" :loading="saveProgress">Mentés</v-btn>
      <v-spacer></v-spacer>
      <v-btn small to="/uzletek" >Vissza a listához</v-btn>
    </v-row>

    <v-form ref="form" v-model="valid">

      <v-row>
        <v-col  lg="3" md="4">
          <v-card class="mb-8">
            <v-card-title>Kategória</v-card-title>
              <v-card-text>
                <v-select item-text="name.hu" item-value="id" outlined dense hide-details :items="categories" v-model="item.store_category_id"></v-select>

              </v-card-text>
            </v-card>

          <v-card class="mb-8">
            <v-card-title>Weboldal</v-card-title>
            <v-card-text>
              <v-text-field label="weboldal" outlined v-model="item.url"></v-text-field>
            </v-card-text>
          </v-card>

          <v-card class="mb-8">
            <v-card-title>Térkép Poi</v-card-title>
              <v-card-text>
                <v-select :items="poiCategories" item-text="name_hu" item-value="id" label="Kategória" outlined v-model="poiCategory"></v-select>
                <v-select :disabled="!poiCategory" :items="filteredPois" item-text="name_hu" item-value="id" label="Poi" outlined v-model="item.poi_id"></v-select>
              </v-card-text>
            </v-card>

          <v-card>
            <v-card-title>Nyitvatartás</v-card-title>
            <v-card-text class="openings">
              <div v-for="day in opening" class="day">
                <div class="d-flex justify-space-between align-center pb-2">
                  {{ days[day.day] }}
                  <v-checkbox dense hide-details v-model="day.isClosed" label="Zárva"></v-checkbox>
                </div>
                <div class="hours">
                  <div class="picker">
                    <v-select  :disabled="day.isClosed" outlined dense hide-details :items="times" v-model="day.from"></v-select>
                  </div>
                  <div class="sep"></div>
                  <div class="picker">
                    <v-select :disabled="day.isClosed"  outlined dense hide-details :items="times" v-model="day.to"></v-select>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col  lg="9" md="8">
          <v-row>
            <v-col>
              <v-card class="mb-4">
                <v-card-title>Alapadatok</v-card-title>
                <v-card-text>


                  <v-tabs v-model="tab">
                    <v-tab v-for="lang in langs">
                      {{  lang }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item class="pt-4" v-for="lang in langs" eager>
                      <v-text-field :label="`Megnevezés - ${lang}`" :rules="[rules.required]" outlined v-model="item.name[lang]"></v-text-field>
                      <v-text-field :label="`Lista név - ${lang}`"  outlined v-model="item.list_name[lang]"></v-text-field>
                      <v-textarea maxlength="2000" counter="2000" rows="3" :label="`Tooltip - ${lang}`" outlined v-model="item.short_description[lang]"></v-textarea>
                      <v-textarea maxlength="5000" counter="5000"  rows="10" :label="`Leírás - ${lang}`" outlined v-model="item.description[lang]"></v-textarea>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="4">
              <v-card>
                <v-card-title>Logo</v-card-title>
                <v-card-subtitle>200 x 200px<br><small>jpg, png, svg, webp</small></v-card-subtitle>
                <v-card-text>
                  <single-image-uploader :ratio="1/1" v-model="item.logo_image_id"></single-image-uploader>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-card class="fill-height">
                <v-card-title>Fő kép</v-card-title>
                <v-card-subtitle>978 x 283 <small>jpg, png, svg, webp</small></v-card-subtitle>
                <v-card-text>
                  <single-image-uploader :ratio="978/283" v-model="item.detail_image_id"></single-image-uploader>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>


      </v-row>




      <v-row>


      </v-row>


    </v-form>
    <v-row>
      <v-col>

      </v-col>
    </v-row>

  </v-container>
</template>
<script>

  import SingleImageUploader from '../components/SingleImageUploader.vue'
  import MultipleImageUploader from "../components/MultipleImageUploader";

  export default {
    components : {
      MultipleImageUploader,
      SingleImageUploader
    },

    data() {
      return {
        poiCategory:null,
        poiCategories:[],
        pois:[],
        valid:false,
        saveProgress:false,
        rules: {
          required: value => !!value || 'Kötelező kitölteni.',
          requiredImage: value => !!value || 'Kötelező feltölteni.',
        },
        showSavedSnackbar:false,
        tab:null,
        item : {
          name:{},
          list_name:{},
          description:{},
          short_description:{
            hu:''
          },
          galleryImageIds:[],
          pictogramImageIds:[],
          listImageId:null,
          mainImageId:null,
          backgroundImageId:null,
          poiId:null,
        },
        opening: [],
        days: [
          'Hétfő',
          'Kedd',
          'Szerda',
          'Csütörök',
          'Péntek',
          'Szombat',
          'Vasárnap'
        ],
        categories:[],
        langs:[]
      }
    },

    computed : {
      times () {
        const times = [];
        var from = new Date();
        var to = new Date();
        from.setHours(0,0,0)
        to.setHours(23,50,0);
        while (from < to) {
          times.push(from.getHours().toString().padStart(2,'0') + ':' + from.getMinutes().toString().padStart(2,'0'));
          from.setMinutes(from.getMinutes() + 5);
        }
        return times;
      },

      isNew(){
        return this.$route.params.id === 'uj'
      },
      filteredPois () {
        if (!this.poiCategory) {
          return []
        }

        return this.pois.filter(x => x.category_id == this.poiCategory)
      }
    },

    methods : {
      nah (e, b) {
        console.log('nah', e,  b)

      },
      save() {
        this.$refs.form.validate();
        console.log('his.valid', this.valid)
        if (!this.valid) {
          const el = document.querySelector('.v-input.error--text');
          if (el) {
            if (el.parentElement.classList.contains('v-window-item')) {

              var tab = el.parentElement;
              var tabIndex = -1;
              document.querySelectorAll('.v-window-item').forEach((item, index) => {
                if (item === tab) {
                  tabIndex = index;
                }
              })

              console.log('loop', tabIndex)


              this.tab = tabIndex
            }
            const y = el.getBoundingClientRect().top - 100;
            window.scrollTo({top: y, behavior: 'smooth'});
          }

          return true;
        }

        console.log('save', this.item)

        this.saveProgress = true;
        this.item['opening'] = this.opening
        this.$api.post('/store/save', this.item).then((response) => {
          this.$snackbar.show('Módosítások elmentve')
          if (this.isNew) {
            this.$router.push(`/uzletek/${response.data.id}`)
          }
          this.saveProgress = false;
        })
      },
      setDefaultOpening () {
        this.opening = this.item.opening && this.item.opening.length ? this.item.opening : [
          {day: 0, from: '08:00', to: '17:30'},
          {day: 1, from: '08:00', to: '17:30'},
          {day: 2, from: '08:00', to: '17:30'},
          {day: 3, from: '08:00', to: '17:30'},
          {day: 4, from: '08:00', to: '17:30'},
          {day: 5, from: '08:00', to: '17:30'},
          {day: 6, from: '08:00', to: '17:30'},
        ]
      }
    },

    mounted () {
      this.$api.get(`/store-category/list`).then((resp)=>{
        this.categories = resp.data;
      })
      this.$api.get(`/poi`).then((resp)=>{
        this.pois = resp.data;

        this.$api.get(`/poi-category`).then((resp)=>{
          this.poiCategories = resp.data;

          if (!this.isNew) {
            this.$api.get(`/store/get?id=${this.$route.params.id}`).then((resp)=>{
              this.item = resp.data;

              if (Array.isArray(this.item.description)) {
                this.item.description = {}
              }

              this.setDefaultOpening();
              if (this.item.poi_id) {
                const poi = this.pois.find(x => x.id == this.item.poi_id);
                this.poiCategory = this.poiCategories.find(x => x.id == poi.category_id).id

              }
            })
          }
          else {
            this.setDefaultOpening();
          }

        })

      })

      this.$api.get('/language').then((resp) => {
        this.langs = resp.data.map(x => x.code);
      })

    }
  }
</script>