import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter);


import AppHome from "../page/AppHome.vue";
import DeviceList from "../page/DeviceList.vue";
import DeviceEdit from "../page/DeviceEdit.vue";
import VideoAdmin from "../page/VideoAdmin.vue";
import PlayListAdmin from "../page/PlayListAdmin.vue";
import PlayListEdit from "../page/PlayListEdit.vue";
import UserList from "../page/UserList.vue";
import StoreList from "../page/StoreList.vue";
import StoreEdit from "../page/StoreEdit.vue";
import TextList from "../page/TextList.vue";
import MapAdmin from "../page/MapAdmin.vue";
import AlertList from "../page/AlertList.vue";
import AlertEdit from "../page/AlertEdit.vue";
import StatsList from "@/page/StatsList.vue";
import PolicyEdit from "../page/PolicyEdit.vue";


const router = new VueRouter({
	routes : [
      { path: '/eszkozok', component: DeviceList },
      { path: '/eszkozok/:id', component: DeviceEdit },
      { path: '/videok', component: VideoAdmin },
      { path: '/listak', component: PlayListAdmin },
      { path: '/listak/:id', component: PlayListEdit },
      { path: '/felhasznalok', component: UserList },
      { path: '/uzletek', component: StoreList },
      { path: '/uzletek/:id', component: StoreEdit },
      { path: '/forditasok', component: TextList },
      { path: '/terkep', component: MapAdmin },
      { path: '/veszhelyzet', component: AlertList },
      { path: '/veszhelyzet/:id', component: AlertEdit },
      { path: '/stats', component: StatsList },
      { path: '/policies/:user', component: PolicyEdit },
      { path: '/', component: AppHome, redirect: { path: '/eszkozok' }  },
      { path: '*', component: AppHome, redirect: { path: '/eszkozok' }  },
	]
})

export default router