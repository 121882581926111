<template>
  <svg ref="svg">
    <g ref="all">

    </g>
    <g id="routes" ref="routes">
      <slot name="routes"></slot>
    </g>
    <g id="pois" ref="pois">
      <slot name="pois"></slot>
    </g>
</svg>
</template>
<script>
 export default {
   props: [
       'url',
       'level'
   ],

   data () {
     return {
       routes:'',
     }
   },

   computed : {

   },

   mounted() {
     const holder = document.createElement('div');

     fetch(this.url)
         .then(r => r.text())
         .then(svgText => {

           holder.innerHTML = svgText;

           const svg = holder.querySelector('svg');

           svg.getAttributeNames().forEach(attr => {
             if (attr == 'id') {
               return;
             }
             this.$refs.svg.setAttribute(attr, svg.getAttribute(attr))
           })


           this.$refs.routes.insertAdjacentHTML( 'beforeend', holder.querySelector('#routes').innerHTML );


           holder.querySelector('#routes').remove();

           this.$refs.all.innerHTML  = svg.innerHTML

           setTimeout(() => {
             this.$map.addSvg(this.level * 1, this.$refs.svg)
           }, 1000)

         })
   }
 }
</script>