<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-btn  @click="save" color="primary">Mentés</v-btn>
      <v-spacer></v-spacer>
      <v-btn small to="/veszhelyzet" >Vissza a listához</v-btn>
    </v-row>

    <v-row>
      <v-col cols="9">
        <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field v-model="item.name" outlined label="Megnevezés"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="colors">
                    <div @click="selectColor(color)" :class="{'active' : color.bg == item.bg_color}" v-for="color in colors" :style="`background: ${color.bg};color:${color.font}`">A</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-editor">
                    <quill-editor
                        ref="quillEditor"
                        class="editor"
                        v-model="item.text"
                        :options="options"
                        :style="`background: ${item.bg_color};color:${item.font_color}`"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-card-title>Státusz</v-card-title>
          <v-card-text>
            <template v-if="item.is_active">
              <v-icon color="success">mdi-check-circle</v-icon> Aktív
            </template>
            <template v-else>
              <v-icon>mdi-stop-circle-outline</v-icon> Inaktív
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="item.is_active" color="primary" @click="inactiveConfirm=true">Inaktiválás</v-btn>
            <v-btn v-else :disabled="!item.id" color="primary" @click="activeConfirm=true">Aktiválás</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="activeConfirm" width="520">
      <v-card>
        <v-card-title>Biztosan aktiválod a vészhelyzeti üzemmódot?</v-card-title>
        <v-card-text class="pa-4"></v-card-text>
        <v-card-actions>
          <v-btn @click="activeConfirm=false">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="doActivate">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="inactiveConfirm" width="520">
      <v-card>
        <v-card-title>Biztosan lekapcsolod a vészhelyzeti üzemmódot?</v-card-title>
        <v-card-text class="pa-4"></v-card-text>
        <v-card-actions>
          <v-btn @click="inactiveConfirm=false">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="doInActivate">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>


  export default {
    data() {
      return {
        activeConfirm: false,
        inactiveConfirm: false,
        colors:[
          {bg: '#FFF', font: '#000'},
          {bg: '#1f2022', font: '#FFF'},
          {bg: '#e50101', font: '#FFF'},
          {bg: '#dc913c', font: '#FFF'},
          {bg: '#00A4E4', font: '#FFF'},
          {bg: '#7CB138', font: '#FFF'},
        ],
        item : {
          text: '',
          bg_color: '',
          font_color: '',
        },
        options : {
          placeholder: 'Írj ide..',
          modules: {
            toolbar: [
              [{ 'size': [false, 'large'] }],
              [{ 'align': [] }],
              ['bold'],
              ['clean']
            ]
          },
        }
      }
    },

    computed : {
      isNew(){
        return this.$route.params.id === 'uj'
      },
    },

    methods : {
      doActivate () {
        this.$api.post(`/alert/activate/${this.item.id}`).then((response) => {
          this.$snackbar.show('Aktiválva');
          this.reload();
          this.activeConfirm = false;
        })
      },
      doInActivate () {
        this.$api.post(`/alert/inactivate/${this.item.id}`).then((response) => {
          this.$snackbar.show('Aktiválva')
          this.reload();
          this.inactiveConfirm = false;
        })
      },
      selectColor (color) {
        this.item.bg_color = color.bg;
        this.item.font_color = color.font;
      },

      save() {

        this.$api.post('/alert/save', this.item).then((response) => {
          this.$snackbar.show('Módosítások elmentve')
          if (this.isNew) {
            this.$router.push(`/veszhelyzet/${response.data.id}`);
            this.reload();
          }
        })

      },
      reload () {
        this.$api.get(`/alert/get?id=${this.$route.params.id}`).then((resp)=>{
          this.item = resp.data;
        });
      },
    },

    mounted () {


      if (!this.isNew) {
        this.reload();
        this.loadStatuses()
      }
    }
  }
</script>