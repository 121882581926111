<template>
  <v-img class="uploader-placeholder" :aspect-ratio="ratio" :src="src" @load="loading=false">

    <div v-if="loading" class="preload-box fill-height justify-center align-center d-flex flex-column">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <div v-if="loadingStep === 1" class="pt-3">
        Feltöltés indítása...
      </div>
      <div class="progress" v-else-if="loadingStep === 2">
        <div class="percent">{{ percent }} %</div>
        <div class="bytes">{{ showbyte(uploadedSize) }} / {{ showbyte(totalSize) }}</div>
      </div>
      <div v-else-if="loadingStep === 3" class="pt-3">
        Kép betöltése...
      </div>
    </div>

    <v-btn v-else-if="loaded" @click="deleteImage" fab elevation="2" class="delete-button" color="error">
      <v-icon >mdi-close</v-icon>
    </v-btn>
    <div v-else-if="hasError" class="upload-error">
      <div class="title">Nem megfelelő formátum!</div>
      <div class="text">
        Engedélyezett formátum <b>jpg</b>, <b>webp</b>, <b>png</b>, <b>svg</b>
      </div>
      <v-btn @click="clearError" color="mt-6" small>Másik fájl választása</v-btn>

    </div>
    <label v-else class="fill-height">
      <input ref="files" accept="image/jpeg, image/webp, image/png, image/svg+xml" @change="fileChanged" type="file">
      <v-icon>mdi-upload</v-icon>
      <small>tallózás</small>
    </label>


  </v-img>
</template>

<script>

  export default {

    components : {
    },

    props: [
      'value',
      'ratio',
    ],
    data() {
      return {
        src: null,
        uploading:false,
        hasError:false,
        totalSize:0,
        uploadedSize:0,
        percent:0,
        loadingStep:0,
        loading:false
      }
    },

    computed : {
      loaded(){
        return !this.loading && this.src
      }
    },

    methods : {
      showbyte (byte) {
        const mb = byte / 1024 / 1024;

        return mb.toFixed(2) + ' MB'
      },
      uploadProgress (progressEvent) {
        this.totalSize = progressEvent.total
        this.uploadedSize = progressEvent.loaded
        this.percent = (this.uploadedSize / this.totalSize * 100).toFixed(1)
      },
      clearError () {
        this.uploading = false;
        this.hasError = false;
      },
      handleError () {
        this.uploading = false;
        this.hasError = true;
      },
      deleteImage (){
        this.src = null;
        this.loaded = false;
        this.$emit('input', null);
      },
      uploaded (saveResp) {
        this.$emit('input', saveResp.data.id);
        this.key = saveResp.data.key;
        this.uploading = false;
      },
      getUploadDetails (args) {
        return new Promise((resolve, reject) => {

          this.$api.post('/image/backblaze', {
            filetype: args.file.type,
          })
              .then((resp) => {
                const data = resp.data;
                const extension = args.file.name.split('.').pop();
                const filename = data.filename + '.' + extension;

                resolve({
                  file: args.file,
                  uploadUrl: data.uploadUrl,
                  authToken: data.authorizationToken,
                  filename
                })
              })
              .catch(reject)
        })
      },
      upload(args) {
        return new Promise((resolve, reject) => {

          this.loadingStep = 2;
          this.$forceUpdate();

          this.$api.post(args.uploadUrl, args.file, {
            onUploadProgress: this.uploadProgress,
            headers: {
              Authorization: args.authToken,
              'X-Bz-File-Name' : args.filename,
              'X-Bz-Content-Sha1' : 'do_not_verify',
              'Content-Type' : args.file.type
            }
          }).then((resp) => {
            resolve(args.filename)
          })
              .catch(reject)

        })
      },
      saveImage (key) {
        return this.$api.post('/image/save', {
          key: key
        })
      },
      fileChanged () {

        this.loading = true;
        this.loadingStep = 1;
        this.$forceUpdate();
        this.getUploadDetails({file: this.$refs.files.files[0]})
            .then(this.upload)
            .then(this.saveImage)
            .then(this.uploaded)
            .catch(this.handleError)

      },
      loadImage() {
        this.loading = true;
        this.loadingStep = 3;
        this.$forceUpdate();
        this.$api.get(`/backblaze/url?id=${this.value}`).then((resp) => {
          this.src = resp.data;
        })
      }
    },

    mounted() {

      if (this.value) {
        this.loadImage()
      }


    },

    watch : {
      value (newValue) {
        if (newValue) {
          this.loadImage()
        }
        else {
          this.src = null;
        }
      }
    }
  }
</script>