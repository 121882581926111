import Vue from 'vue'
import api from "./api";
import axios from 'axios'
import router from './router'
import { jwtDecode } from "jwt-decode";

var auth = new Vue({
  router,

  data: () => ({
    jwt: '',
    loading: true,
    formState:'login',
    user: null,
    permissions: []
  }),

  computed : {
    isAdmin () {
      return this.user.permission === 1
    },
    loggedIn () {
      return this.jwt
    },
    isDialogOpen () {
      return !this.loggedIn
    },
    verifyToken () {
      const parts = window.location.href.split('verifyToken=');
      if (parts.length == 2) {
        return parts[1];
      }
      return false;
    }
  },

  methods: {
    login (jwt) {
      this.jwt = jwt;
      window.localStorage.setItem('jwt', jwt);
      axios.defaults.headers.jwt = jwt;
    },
    logout () {
      this.jwt = null;
      window.localStorage.removeItem('jwt');
      axios.defaults.headers.jwt = null;
    },
    can(resource, operation) {
      return this.permissions.findIndex(([r, o]) => r === resource && o.includes(operation)) !== -1;
    },
  },

  created() {
    this.jwt = window.localStorage.getItem('jwt');


    if (this.verifyToken) {
      this.logout()
      this.$router.push('/auth')
      this.formState = 'password-reset'
    }
  },

  watch : {
    isDialogOpen (newValue) {
      if (newValue === false) {
        this.formState = 'login'
      }
    },
    jwt(newValue) {
      const sub = newValue ? jwtDecode(newValue).sub : undefined;
      this.user = sub ? JSON.parse(sub) : null;

      if(this.user){
        api.$api.get('/policies/users/me').then(({data}) => {
          this.permissions = Object.entries(data);
        });
      } else {
        this.permissions = [];
      }
    }
  }
})

export default {
  install(Vue) {
    Vue.prototype.$auth = auth
  },
  $auth: auth
};