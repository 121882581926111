<template>
  <svg :viewBox="`0 0 20 20`" width="40" :id="`poi-${this.poi.id}`" height="40" :x="x" :y="y" class="poi" :data-is-text="isText" :class="dragClass" @mousedown="startDrag">
    <circle :fill="category.color" :stroke="stroke" :stroke-width="strokeWidth" r="10" cx="10" cy="10"></circle>
    <circle fill="none" stroke="#FFF" stroke-width="0.7" r="9" cx="10" cy="10"></circle>

    <g v-if="category.content_type == 1" >
      <image ref="image" width="12" height="12" :href="image" x="50%" y="50%" transform="translate(-6, -6)"></image>
    </g>
    <g v-else-if="category.content_type == 2">
      <text x="50%" y="50%" font-size="8" font-family="Arial" text-anchor="middle" alignment-baseline="middle" fill="#000">{{ poi.text }}</text>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['poi'],
  data () {
    return {
      image: null,
      drag: false,
      startX:this.poi.x1,
      startY:this.poi.y1,
      img: null,
      imgW:0,
      imgH:0,
    }
  },
  computed : {
    isText () {
      return this.category.content_type == 2;
    },
    stroke () {
      return this.category.color == '#FFF' ? 'black' : '';
    },
    strokeWidth () {
      return this.category.color == '#FFF' ? '1' : '0';
    },
    dragClass () {
      return this.drag ? 'drag' : ''
    },
    hasImageContent () {
      return this.category.contentType == 1 && this.category.shape != 4
    },
    width () {
      return 40
    },
    imageWidth () {
      return this.hasImageContent
          ? 9
          : 18
    },
    x () {
      return this.poi.x1 - (this.width / 2)
    },
    y () {
      return this.poi.y1 - (this.width / 2)
    },
    imageTransform () {
      return ``

      if (!this.imgW) {
        return ''
      }
      const ratio = this.imgW / this.imgH;
      return `translate(-4.5 -${4.5/ratio})`
    },
    category () {
      const result = this.$map.categories.find(x => x.id === this.poi.category_id);
      if (result) {
        return result
      }
      return {}
    }
  },

  methods : {

    startDrag () {
      this.drag = true;
      this.$map.dragPoi = this;
    },

    stopDrag () {
      this.drag = false;
      this.$map.dragPoi = null;
      this.$map.editPoi(this.poi);
    },

    moveDrag (e) {
      if (!this.drag) {
        return
      }

      const xratio = this.$map.currentSvg.clientWidth / this.$map.currentSvg.viewBox.baseVal.width;
      const yratio = this.$map.currentSvg.clientHeight / this.$map.currentSvg.viewBox.baseVal.height;

      this.poi.x1 = e.layerX / xratio;
      this.poi.y1 = e.layerY / yratio;

      const nearest = this.$map.findNearest(this.poi.x1, this.poi.y1)

      this.poi.x2 = nearest.x
      this.poi.y2 = nearest.y
    },
    resetPosition () {
      const nearest = this.$map.findNearest(this.startX, this.startY)

      this.poi.x1 = this.startX;
      this.poi.y1 = this.startY;
      this.poi.x2 = nearest.x
      this.poi.y2 = nearest.y
    },

    loaded () {
      this.imgW = this.img.width;
      this.imgH = this.img.height;
    },

    categoryLoaded () {
      if (this.poi.image_id) {
        this.loadImage(this.poi.image_id)
      }
      if (this.category.image_id) {
        this.loadImage(this.category.image_id)
      }
    },

    loadImage (id) {
      this.$api.get(`/image/url?id=${id}`).then((resp) => {
        this.image = resp.data;

        this.img = new Image()
        this.img.onload = this.loaded
        this.img.src = this.image

      })
    }
  },

  mounted () {
    this.categoryLoaded()
  },

  watch : {
    'poi.category_id' () {
      console.log('watch categoryid', this.poi.category_id)
      this.categoryLoaded()
    },
    '$map.categories' () {
      this.categoryLoaded()
    }
  }
}
</script>