import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import JsonViewer from 'vue-json-viewer'

Vue.use(JsonViewer)
import './scss/index.scss'

Vue.config.productionTip = false
Vue.config.devtools = false;

import Menu from './plugins/menu'
import Snackbar from './plugins/snackbar'
import Api from './plugins/api.js'
import Auth from './plugins/auth.js'
import router from './plugins/router'
import Map from './plugins/map'
import Statfilter from './plugins/statfilter'
import VueQuillEditor from 'quill-vuejs'
import UseDownloadBlob from "./plugins/useDownloadBlob";

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)

Vue.use(Auth)
Vue.use(Api)
Vue.use(Snackbar)
Vue.use(Menu)
Vue.use(Map)
Vue.use(Statfilter)
Vue.use(UseDownloadBlob)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
