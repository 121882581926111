import axios from "axios";
import auth from "./auth.js";
import artiny  from 'axios-retry-tiny';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,

  retry: 3,
  retryDelay: 1000,
  retryCode: ['ECONNABORTED', 'ETIMEDOUT', 500],
  retryBeforeFn: (e) => {
    console.log(`retry #${e.config.__retryCount}: ${e.config.url} : errCode: ${e.code || (e.response && e.response.status)}`);
  }

});
artiny(instance)

instance.interceptors.request.use((config) => {
  const token = auth.$auth.jwt;
  const isB2 = config.url.indexOf('.backblaze.com') !== -1;
  if (token && !isB2) {
    config.headers['jwt'] = token;
  }
  return config;
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (error.response.status === 401) {
    auth.$auth.logout();
    return true;
  }
  return Promise.reject(error);
});


export default {
  install(Vue) {
    Vue.prototype.$api = instance
  },
  $api : instance
};