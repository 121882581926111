<template>
  <v-container fluid>
    <v-row>

      <v-col>
        <v-data-table
          :headers="headers"
          :items="videos"
          :items-per-page="15"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Videók</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="newEditedVideo">Feltöltés</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="videoStat=item" icon class="mr-2">
              <v-icon
                small
              >
                mdi-chart-line
              </v-icon>
            </v-btn>
            <v-btn @click="editedVideo=item" icon class="mr-2">
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn icon @click="deleteVideo=item">
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            {{ time(item.duration) }}
          </template>
          <template v-slot:[`item.view`]="{ item }">
            {{ new Intl.NumberFormat('hu-HU', {maximumSignificantDigits: 3}).format(item.view) }}
          </template>
        </v-data-table>
      </v-col>


    </v-row>



    <v-dialog v-if="deleteVideo" v-model="deleteVideo" width="500">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{deleteVideo.name}}</i> videót?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteVideo=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDeleteVideo">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-if="videoStat" v-model="videoStat" max-width="650">
      <v-card>
        <v-card-title><i>{{ videoStat.name }}</i> megtekintései</v-card-title>
        <v-card-text>
          <video-view :id="videoStat.id"></video-view>
        </v-card-text>
        <v-card-actions>
          <v-btn :href="`/video/view/export/${videoStat.id}?t=${(new Date()).getTime()}`" _target="blank">Export</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="videoStat = null">Bezár</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>





    <v-dialog v-if="editedVideo" v-model="editedVideo" width="500" persistent>
      <v-card>
        <v-card-title v-if="editedVideo.id">Szerkesztés</v-card-title>
        <v-card-title v-else>Feltöltés</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="form" v-model="editedVideoValid">
            <v-row>
              <v-col>
                <v-text-field :rules="[rules.required]" label="Megnevezés" outlined v-model="editedVideo.name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                  <video-uploader v-on:uploading="uploadInProgress" v-model="editedVideo"></video-uploader>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="editedVideo=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="isUploadInProgress" @click="saveEditedVideo">Mentés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




  </v-container>
</template>
<script>

  import VideoUploader from "../components/VideoUploader";
  import VideoView from "@/components/VideoView";
  export default {
    components: {VideoView, VideoUploader},
    data() {
      return {
        headers: [
          {text: 'Név', value: 'name'},
          {text: 'Lejátszások', value: 'view'},
          {text: 'Hossz', value: 'duration'},
          {text: 'Műveletek', value: 'actions', sortable:false, width: 160},
        ],
        rules : {
          required: value => !!value || 'Ezt is töltsd ki',
        },
        editedVideoValid:false,
        editedPlaylistValid:false,
        videos: [],
        playlists: [],
        deleteVideo: null,
        deletePlayList: null,
        editedVideo:null,
        editedPlayList:null,
        isUploadInProgress:false,
        videoStat:null
      }
    },

    methods : {
      time (sec) {
        const minute = Math.floor(sec / 60);
        const second = sec % 60;

        return [
          minute.toString().padStart(2, '0'),
          second.toString().padStart(2, '0'),
        ].join(':')
      },
      uploadInProgress (value) {
        this.isUploadInProgress = value;
      },
      showDeleteItem (item) {
        this.deleteItem = item;
        this.showDelete = true;
      },
      confirmDeleteVideo() {
        this.$api.get(`/video/delete/${this.deleteVideo.id}`).then(()=>{
          this.loadVideos();
          this.deleteVideo = null;
        })
      },
      confirmDeletePlayList() {
        this.$api.get(`/playlist/delete/${this.deletePlayList.id}`).then(()=>{
          this.loadPlayLists();
          this.deletePlayList = null;
        })
      },

      newEditedPlayList()
      {
        this.editedPlayList = {
          name:'',
          videoIds:[]
        }
      },
      saveEditedPlayList()
      {
        this.$refs.playlistForm.validate();
        if (!this.editedPlaylistValid) {
          return false;
        }

        this.$api.post('/playlist/save', this.editedPlayList).then(() => {
          this.$snackbar.show('Módosítások elmentve');
          this.editedPlayList = null;
          this.loadPlayLists();
        })
      },

      newEditedVideo()
      {
        this.editedVideo = {
          name:'',
          image_id:null,
          duration:0
        }
      },
      saveEditedVideo()
      {
        this.$refs.form.validate();
        if (!this.editedVideoValid) {
          return false;
        }


        this.$api.post('/video/save', this.editedVideo).then(() => {
          this.$snackbar.show('Módosítások elmentve');
          this.editedVideo = null;
          this.loadVideos();
        })
      },

      loadVideos()
      {
        this.$api.get(`/video`).then((resp)=>{
          this.videos = resp.data
        })
      },
      loadPlayLists()
      {
        this.$api.get(`/playlist`).then((resp)=>{
          this.playlists = resp.data
        })
      }
    },

    mounted () {
      this.loadVideos();
      this.loadPlayLists();
    }
  }
</script>