<template>
  <v-container fluid >

    <v-row class="pa-4">
      <v-btn  @click="save" color="primary">Mentés</v-btn>
      <v-spacer></v-spacer>
      <v-btn small to="/listak" >Vissza a listához</v-btn>
    </v-row>


    <v-form  ref="form" v-model="isValid">

      <v-row>
        <v-col cols="9">
          <v-card>
            <v-card-title>Alapadatok</v-card-title>
            <v-card-text class="pa-4">
                  <v-text-field :rules="[rules.required]" label="Megnevezés"  outlined v-model="playlist.name"></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3" >

          <v-card>
            <template v-if="!playlist.id">
              <v-card-title>
                <v-icon>mdi-help-circle</v-icon> Státusz
              </v-card-title>
            </template>
            <template v-else>
              <v-card-title v-if="playlist.is_active">
                <v-icon color="success">mdi-check-circle</v-icon> Aktív
              </v-card-title>
              <v-card-title v-else>
                <v-icon color="red">mdi-alert-circle</v-icon> Inaktív
              </v-card-title>
            </template>
            <v-card-text >

              <div v-if="playlist.timings && playlist.timings.length">Időzítés:</div>
              <ul>
                <li v-for="event in playlist.timings">
                  <template v-if="event.start != event.end">
                    {{ date(event.start) }} - {{ date(event.end) }}
                  </template>
                  <template v-else>
                    {{ date(event.start)  }}
                  </template>
                </li>
              </ul>


            </v-card-text>
            <v-card-actions class="pa-4">
              <v-btn :disabled="!playlist.id" v-if="!playlist.is_active" @click="showActivate()" color="light-green darken-1 white--text">Aktiválás</v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="!playlist.id" @click="showTiming()" color="primary">Időzítés</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="6">

          <v-card>
            <v-card-title>
              Lejátszási lista
              <v-spacer></v-spacer>
              <small>{{ sumTime }}</small>
            </v-card-title>
            <v-card-text class="pa-4">
              <div class="overflow-auto video-playlist-list right">
                <div v-if="!list.length" class="pa-6">
                  Adj hozzá videókat
                  <v-icon color="grey" small>mdi-arrow-right</v-icon>
                </div>
                <nested-draggable :sub-videos="list"></nested-draggable>
              </div>
            </v-card-text>
          </v-card>


        </v-col>
        <v-col cols="6">



          <v-card>
            <v-card-title>Videók</v-card-title>
            <v-card-text class="pa-4">

              <v-text-field dense placeholder="Keresés" clearable v-model="filterText" outlined></v-text-field>


              <div class="overflow-auto video-playlist-list">
                <v-list dense>


                  <v-list-item @click="addGroup()">
                    <v-list-item-icon>
                      <v-icon>mdi-folder</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Csoport hozzáadása</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>


                  <v-list-item @click="add(video)" v-for="video, i in filteredVideos" :key="i">
                    <v-list-item-icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="d-flex">
                        {{ video.name}}
                        <v-spacer></v-spacer>

                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <small>{{ time(video.duration) }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="!filteredVideos.length">
                    <v-list-item-content>
                      <v-list-item-subtitle>Nincs találat</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>


        </v-col>
      </v-row>



    </v-form>

    <v-dialog v-model="activateDialog" max-width="400">
      <v-card>


        <v-card-title>Biztosan aktívvá teszed ezt a listát?</v-card-title>
        <v-card-text v-if="currentPlaylist && currentPlaylist.id != playlist.id">
          A jelenleg aktív lista ami ezáltal inaktiválódik: {{ currentPlaylist.name }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="activateDialog=false">Mégsem</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="doActivate">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="timingDialog" max-width="900">
      <v-card>
        <v-card-title>Állítsd be, hogy mikor legyen aktív ez a lista</v-card-title>
        <v-card-text>
          <calendar-picker :playlist="playlist"></calendar-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="timingDialog=false">Mégsem</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="timingDialog=false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>

  import draggable from 'vuedraggable';
  import NestedDraggable from "@/components/Nested";
  import CalendarPicker from "@/components/CalendarPicker";

  export default {
    components: {
      CalendarPicker,
      NestedDraggable,
      draggable
    },
    data() {
      return {
        myArray:[],
        filterText:'',
        isValid:false,
        headers: [
          {text: 'Név', value: 'name'},
          {text: 'Műveletek', value: 'actions', sortable:false, width: 120},
        ],
        rules : {
          required: value => !!value || 'Ezt is töltsd ki',
        },
        videos: [],
        playlist: {
          name: '',
          video_ids: []
        },
        list:[],
        currentPlaylist:null,
        activateDialog:false,
        timingDialog:false,


      }
    },

    computed : {

      filteredVideos () {
        if (!this.filterText) {
          return  this.videos;
        }
        return this.videos.filter(x => x.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1)
      },
      sumTime () {
        let minDuration = 0;
        let maxDuration = 0;

        this.list.forEach((item) => {
          if (item.subVideos && item.subVideos.length) {

            const durations = item.subVideos.map(x => x.duration * 1 );

            console.log('durs', durations)

            const min = Math.min(...durations);
            const max = Math.max(...durations);

            minDuration += min;
            maxDuration += max;
          }
          else if (item.duration) {
            minDuration += item.duration;
            maxDuration += item.duration;
          }
        })

        if (minDuration === maxDuration) {
          return this.time(minDuration)
        }

        return this.time(minDuration) + ' - ' + this.time(maxDuration)
      },
      id () {
        return this.$route.params.id;
      },
      isNew(){
        return this.$route.params.id === 'uj'
      },
    },

    methods : {

      showTiming () {
        this.timingDialog = true;
      },

      doActivate () {
        this.$api.post('/playlist/active', {'id' : this.playlist.id}).then(() => {
          this.loadPlaylist();
          this.activateDialog = false;
        })
      },

      date (time) {
        return (new Date(time * 1000)).toLocaleDateString('hu-HU', {month:'long', day: 'numeric'})
      },

      showActivate () {
        this.activateDialog = true;
      },

      time (sec) {
        const minute = Math.floor(sec / 60);
        const second = sec % 60;

        return [
          minute.toString().padStart(2, '0'),
          second.toString().padStart(2, '0'),
        ].join(':')
      },

      getName (id) {
        return this.getVideoById(id).name;
      },

      getVideoById (id) {
        const video = this.videos.find(x => x.id == id);
        return video ? video : {};
      },

      add (video) {
        video.subVideos = []
        this.list.push(video)
      },

      addGroup () {
        const group = {
          name: 'A-B csoport',
          subVideos: [],
          isGroup:true
        };
        this.list.push(group)
        return group;
      },


      loadVideos()
      {
        return new Promise(resolve => {
          this.$api.get(`/video`).then((resp)=>{
            this.videos = resp.data
            resolve()
          })
        })
      },

      loadPlaylist()
      {
        return new Promise(resolve => {

          if (this.isNew) {
            resolve();
            return
          }

          this.$api.get(`/playlist/get?id=${this.id}`).then((resp)=>{
            this.playlist = resp.data;
            this.list = [];

            const groups = {};

            this.playlist.videos.forEach((video) => {

              if (video.group_id) {
                let group = null;
                if (!groups[video.group_id]) {
                  groups[video.group_id] = this.addGroup()
                }
                groups[video.group_id].subVideos.push(this.getVideoById(video.id))
              }
              else {
                this.add(this.getVideoById(video.id))
              }
            })
          })

        })
      },

      uid () {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
      },

      save () {

        this.$refs.form.validate();
        console.log('his.valid', this.isValid)
        if (!this.isValid) {
          const el = document.querySelector('.v-input.error--text');
          if (el) {
            if (el.classList.contains('english')) {
              this.tab = 1
            }
            const y = el.getBoundingClientRect().top - 100;
            window.scrollTo({top: y, behavior: 'smooth'});
          }

          return true;
        }

        const ids = []
        this.list.forEach(video => {
          if (video.subVideos && video.subVideos.length) {
            const group = this.uid()
            video.subVideos.forEach(subvideo => {
              ids.push({id: subvideo.id, group: group})
            })
          }
          else {
            ids.push({id: video.id})
          }
        })


        this.$api.post('/playlist/save', {
          id: this.playlist.id,
          name: this.playlist.name,
          videos : ids,
          timings: this.playlist.timings
        }).then((resp) => {
          this.$snackbar.show('Módosítások elmentve')

          if (this.isNew) {
            this.$router.push(`/listak/${resp.data.id}`)
          }
        })

      },

      loadActivePlaylist () {
        this.$api.get('/playlist/active').then(resp => {
          this.currentPlaylist = resp.data
        })
      }
    },

    mounted () {
      this.loadActivePlaylist()
      this.loadVideos()
          .then(this.loadPlaylist)
          .then(() => {
            console.log('loaded')
          });



    }
  }
</script>