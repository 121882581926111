<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-btn small to="/felhasznalok">Vissza a listához</v-btn>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-chip class="mb-5">{{ user }}</v-chip>
        <v-row>
          <v-col v-for="[resource, operations] in policyElements" cols="3">
            <v-card>
              <v-card-title>{{
                resourceMenuMap.get(resource) ?? resource
              }}</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row v-for="operation in operations">
                    <v-switch
                      :value="{ resource, operation }"
                      :disabled="disabled"
                      :label="operationLabelMap.get(operation) ?? operation"
                      :loading="hasLoadingPolicy(resource, operation)"
                      :value-comparator="valueComparator"
                      @change="handleChange($event, resource, operation)"
                    />
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      policyElements: [],
      permissions: {},
      loadingPolicy: new Set(),
      resourceMenuMap: new Map(
        Object.entries({
          "/store": "Üzletek",
          "/alert": "Vészhelyzet üzemmód",
          "/map": "Térkép",
          "/video": "Videók",
          "/playlist": "Lejátszási listák",
          "/device": "Eszközök",
          "/user": "Felhasználók",
          "/text": "Fordítások",
          "/stats": "Statisztikák",
          "/policies": "Jogosultságkezelés",
        })
      ),
      operationLabelMap: new Map(
        Object.entries({
          manage: "menedzsment",
          "unrestricted-list": "korlátozás nélküli lista",
        })
      ),
    };
  },
  computed: {
    disabled() {
      return this.$auth.user?.email === this.$route.params.user;
    },
    user() {
      return this.$route.params.user;
    },
  },
  methods: {
    loadPolicies() {
      this.$api.get("/policies/users/me").then(({ data }) => {
        this.policyElements = Object.entries(data);
      });
    },
    loadPermissions() {
      this.$api.get(`/policies/users/${this.user}`).then(({ data }) => {
        this.permissions = data;
      });
    },
    valueComparator(a, b) {
      const { resource, operation } = b ?? a;

      return (
        this.permissions[resource]?.findIndex((operations) =>
          operations.includes(operation)
        ) > -1
      );
    },
    addLoadingPolicy(resource, operation) {
      this.loadingPolicy = new Set(
        this.loadingPolicy.add(JSON.stringify({ resource, operation }))
      );
    },
    deleteLoadingPolicy(resource, operation) {
      this.loadingPolicy.delete(JSON.stringify({ resource, operation }));
      this.loadingPolicy = new Set(this.loadingPolicy);
    },
    hasLoadingPolicy(resource, operation) {
      return this.loadingPolicy.has(JSON.stringify({ resource, operation }));
    },
    handleChange(event, resource, operation) {
      this.addLoadingPolicy(resource, operation);

      const action = event ? "add" : "remove";

      this.$api
        .put(`/policies/users/${this.user}`, {
          action,
          resource,
          operation,
        })
        .then(() => {
          this.loadPermissions();
        })
        .finally(() => {
          this.deleteLoadingPolicy(resource, operation);
        });
    },
  },
  mounted() {
    this.loadPolicies();
    this.loadPermissions();
  },
};
</script>
