export default {
  install(Vue) {
    Vue.prototype.$useDownloadBlob = useDownloadBlob
  },
  $useDownloadBlob: useDownloadBlob
};

export function useDownloadBlob(blob, fileName) {
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
  
    document.body.appendChild(link);
  
    link.click();
    link.remove();
  
    URL.revokeObjectURL(url);
  }
  