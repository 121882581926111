import Vue from 'vue'

var vue = new Vue({
    data: () => ({
        date:null,
        month:null,
        dateType: 'Napi',
        months:[],
        sortBy: [{ key: 'count', order: 'desc' }],
        text: ''
    }),

    computed : {
    },

    methods: {
        getQuery () {
            let query = {};

            if (this.dateType === 'Napi') {
                query.date = this.date;
            }
            else {
                query.month = this.month;
            }

            query = new URLSearchParams(query);

            return query.toString();
        },
        setMonths () {
            let start = new Date();

            for (let i=0;i<12;i++) {
                this.months.push([
                    start.getFullYear(),
                    (start.getMonth() + 1).toString().padStart(2, '0')
                ].join('-'))
                start.setMonth(start.getMonth() - 1);
            }

            this.month = this.months[0];

        },
        setDate () {
            let date = new Date()
            this.date = [
                date.getFullYear(),
                (date.getMonth() + 1).toString().padStart(2, '0'),
                (date.getDate()).toString().padStart(2, '0'),
            ].join('-');
        }
    },

    created () {
        this.setDate()
        this.setMonths()
    }
})

export default {
    install (Vue) {
        Vue.prototype.$statfilter = vue
    }
}